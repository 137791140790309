import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Can from "../../routes/Can";

import PageContainer from "../../components/UI/PageContainer";
import CustomKPI from "../Planner/components/CustomKPI";

const ScreensHomeWelcome = () => {
  const { t } = useTranslation();

  const {
    user: { username, trainers },
  } = useSelector((state) => state.auth);
  const renderOption = (icon, title, description, url) => {
    return (
      <div className="card white darken-3">
        <div className="card-content black-text">
          <p>
            <i className="material-icons background-round mr-5 gradient-45deg-light-blue-gray darken-3 white-text">
              {icon}
            </i>
            {t(`${description}`)}
          </p>
        </div>
        <div className="card-action">
          <NavLink
            exact
            activeClassName="active"
            className="btn light-blue darken-1"
            to={url}
          >
            <span data-i18n="Modern">{t(`${title}`)}</span>
          </NavLink>
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <h2>{`${t("Bienvenido")} ${trainers.length === 0 || !trainers[0] ? username : trainers[0].nombre}`}</h2>
      <div className="row">
        <CustomKPI />
      </div>
      <div className="row margin">
        <Can
          perform="Alumnos:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "people",
                "welcome-title-alumnos",
                "welcome-description-alumnos",
                "/alumnos"
              )}
            </div>
          )}
        />
        <Can
          perform="Cursos:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "event",
                "welcome-title-cursos",
                "welcome-description-cursos",
                "/cursos"
              )}
            </div>
          )}
        />
        <Can
          perform="Tareas:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "assignment",
                "welcome-title-tareas",
                "welcome-description-tareas",
                "/tareas"
              )}
            </div>
          )}
        />
        <Can
          perform="AlumnosTitulaciones:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "card_membership",
                "welcome-title-titulaciones",
                "welcome-description-titulaciones",
                "/alumnos/titulaciones"
              )}
            </div>
          )}
        />
        <Can
          perform="Diplomas:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "card_membership",
                "welcome-title-diplomas",
                "welcome-description-diplomas",
                "/diplomas"
              )}
            </div>
          )}
        />
        <Can
          perform="Documentos:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "inbox",
                "welcome-title-documentos",
                "welcome-description-documentos",
                "/documentos"
              )}
            </div>
          )}
        />
        {/* Opciones para Organizador */}
        <Can
          perform="Planner:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "assignment",
                "Planificar",
                "Gestionar planificaciones",
                "/planner"
              )}
            </div>
          )}
        />
        <Can
          perform="Planner:calendar"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "event",
                "Calendario",
                "Visualizar Planificaciones",
                "/planner/calendario"
              )}
            </div>
          )}
        />
        {/* Opciones para Formadores */}
        <Can
          perform="Planner:calendar_trainer"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "event",
                "Agenda",
                "Visualizar Agenga",
                "/planner/agenda"
              )}
            </div>
          )}
        />
        <Can
          perform="Planner:disponibilidad_trainer"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "event",
                "Disponibilidad",
                "Gestionar Disponibilidad",
                "/planner/disponibilidadFormador"
              )}
            </div>
          )}
        />

        {/* Opciones para alumnos */}
        <Can
          perform="AreaAlumno:AlumnosCursos:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "event",
                "welcome-title-cursos-alumno",
                "welcome-description-cursos-alumno",
                "/area-alumno/cursos"
              )}
            </div>
          )}
        />
        <Can
          perform="AreaAlumno:Documentos:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "inbox",
                "welcome-title-documentos",
                "welcome-description-documentos",
                "/area-alumno/documentos"
              )}
            </div>
          )}
        />
        <Can
          perform="AreaAlumno:TareasAlumnos:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "assignment",
                "welcome-title-tareas",
                "welcome-description-tareas",
                "/area-alumno/tareas"
              )}
            </div>
          )}
        />
        <Can
          perform="AreaAlumno:AlumnosDiplomas:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "card_membership",
                "welcome-title-diplomas",
                "welcome-description-diplomas",
                "/area-alumno/diplomas"
              )}
            </div>
          )}
        />
        <Can
          perform="BibliotecaLibros:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "book",
                "welcome-title-biblioteca",
                "welcome-description-biblioteca",
                "/biblioteca/libros"
              )}
            </div>
          )}
        />
        <Can
          perform="Customers:index"
          yes={() => (
            <div className="col s12 m4">
              {renderOption(
                "business",
                "welcome-title-clientes",
                "welcome-description-clientes",
                "/clientes"
              )}
            </div>
          )}
        />
      </div>
    </PageContainer>
  );
};

export default ScreensHomeWelcome;