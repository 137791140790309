import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import i18n from 'i18next';
import { useParams, Link } from "react-router-dom";

import '../User/Login.css';
import './ConfirmarDisponibilidad.css';
import { confirmarDisponibilidad } from '../../actions/trainer';

import { showError, showSuccess } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';
import { useEffect, useState } from 'react';

import * as Icons from "@material-ui/icons";

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    icon,
    id,
    key
}) => (

    <div className="row margin">
        <div className="input-field col s12">
            <i className="material-icons prefix pt-2">{icon}</i>
            <input {...input} key={key} id={id} type={type} />
            <label htmlFor={input.name} className="center-align">
                {label}
            </label>
            <small className="errorTxt6">
                <div id="crole-error" className="error">
                    {touched && error}
                </div>
            </small>
        </div>
    </div>

);

const validate = (values) => {
    const errors = {};

    if (!values.password) {
        errors.password = i18n.t('validator-error-password');
    }

    if (!values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-confirm-password');
    }

    if (values.password !== values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-password-confirm-password');
    }

    return errors;
}

const ScreensConfirmarDisponibilidad = ({ history, submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { token } = useParams();

    const [buttonsVisible, setButtonsVisible] = useState(true);

    const [titulo, setTitulo] = useState('¿Confirma disponibilidad?');

    const [tituloStyle, setTituloStyle] = useState({ fontSize: '20px' });

    const [mostrarEnlace, setMostrarEnlace] = useState(false);

    const handleConfirmAvailability = (isConfirmed) => {
        if (isConfirmed) {
            // Lógica para manejar la confirmación de disponibilidad
            dispatch(
                confirmarDisponibilidad({
                    confirma: 1,
                    token: token
                })
            ).then((res) => {
                if (res.ok) {
                    showSuccess(t('message-title-success'), 'Disponibilidad confirmada');
                    setButtonsVisible(false);
                    setTitulo('Sesión confirmada');
                    setTituloStyle({ fontSize: '20px', color: 'green' });
                    setMostrarEnlace(true);
                    //history.replace('/login');
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });

        } else {
            // Lógica para manejar la no confirmación de disponibilidad
            dispatch(
                confirmarDisponibilidad({
                    confirma: 0,
                    token: token
                })
            ).then((res) => {
                if (res.ok) {
                    showSuccess(t('message-title-success'), 'Por favor, comuníquese con Operaciones para avisar que deniega la sesión.');
                    setButtonsVisible(false);
                    setTitulo('Sesión denegada');
                    setTituloStyle({ fontSize: '20px', color: 'red' });
                    //history.replace('/login');
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });
        }

    };

    const [decodedParam, setDecodedParam] = useState('');

    useEffect(() => {
        // Función para decodificar Base64
        const decodeBase64 = (base64) => {
            return decodeURIComponent(escape(window.atob(base64)));
        };

        // Función para extraer el último parámetro de la URL
        const getLastUrlParameter = (url) => {
            const parts = url.split('/');
            return parts[parts.length - 1];
        };

        // Obtener la URL actual
        const url = window.location.href;

        // Extraer y decodificar el último parámetro
        const encodedParam = getLastUrlParameter(url);
        const decoded = decodeBase64(encodedParam);

        // Actualizar el estado con el parámetro decodificado
        setDecodedParam(decoded);
    }, []);

    // Dividir la información en partes
    const infoParts = decodedParam.split(' | ');
    // Verificar si las fechas son iguales
    const isSameDay = infoParts[3] === infoParts[4];

    const renderForm = () => {
        
        return (
            <>
                <form className="login-form" onSubmit={handleSubmit(handleConfirmAvailability)}>
                    <div className="row">
                        <div className="input-field col s12" style={{ textAlign: 'center' }}>
                            <img src="/assets/images/logo.png" alt="ERP - Sistema de Gestión" className="responsive-img" style={{ maxWidth: "35%", "marginRight": "15px" }} />
                            <h5 style={tituloStyle}>{titulo}</h5>
                            {mostrarEnlace && <a href="/planner/agenda">IR A LA AGENDA</a>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <div className="info-container">

                                <p><Icons.Person className="info-icon" /> <span className="info-value">{infoParts[1]}</span></p>
                                <p><Icons.Book className="info-icon" /> <span className="info-value">{infoParts[0]} ({infoParts[2]})</span></p>
                                <p><Icons.Event className="info-icon" /> <span className="info-value">{infoParts[3]} hasta {infoParts[4]}</span></p>

                                {/* <p><Icons.HourglassEmpty className="info-icon" /> <span className="info-value">{infoParts[5]}</span></p> */}
                                {infoParts[6] && (
                                    <p>
                                        <Icons.LocationOn className="info-icon" />
                                        <a
                                            className="info-value info-link"
                                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(infoParts[6])}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {infoParts[6]}
                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {buttonsVisible && (
                    <div className="row">
                        <div className="input-field col s6">
                            <button type="button" className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12" onClick={() => handleConfirmAvailability(true)}>
                                SI
                            </button>
                        </div>
                        <div className="input-field col s6">
                            <button type="button" className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12" onClick={() => handleConfirmAvailability(false)}>
                                NO
                            </button>
                        </div>
                    </div>
                    )}
                </form>
            </>
        );
    };

    return (
        <div className="row">
            <div className="col s12">
                <div className="container">
                    <div id="login-page" className="row">
                        <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">

                            {renderForm()}

                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'confirmarDisponibilidadForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensConfirmarDisponibilidad);