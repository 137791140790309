import React from "react";
import { useTranslation } from "react-i18next";

function CustomAgenda({ event, title, color }) {
  const { t } = useTranslation();
  return (
    <div className="agenda" style={{ color: color }}>
      <h5 className="agenda-title">{title}</h5>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.customer")}:</strong>{" "}
        <span style={{ fontWeight: 'bold' }}>{event.client}</span>
      </p>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.center")}:</strong>{" "}
        {event.center} -{" "}
        <strong>{t("screens.planner.eventDetailsModal.room")}:</strong>{" "}
        <span style={{ fontWeight: 'bold' }}>{event.room}</span>
      </p>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.trainer")}:</strong>{" "}
        <span style={{ fontWeight: 'bold' }}>{event.trainer1}</span>
      </p>      
      
      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.trainingType")}:</strong>{" "}
        <span style={{ fontWeight: 'bold' }}>{event.trainingType}</span>
      </p>
    </div>
  );
}

export default CustomAgenda;
