import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card } from "antd";
import ReactQuill from "react-quill";
import moment from "moment";
import { Button, Typography } from "antd";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { CommentOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";



const { Text } = Typography;
const AuthorText = ({ author, visibilidadFormador }) => (
  <Text strong style={{ fontSize: "16px", marginBottom: "4px" }}>
    {visibilidadFormador ? <EyeOutlined style={{ color: "green", marginRight: "8px" }} /> : <EyeInvisibleOutlined style={{ color: "red", marginRight: "8px" }} />}
    {author}

  </Text>
);

const DateText = ({ date }) => (
  <Text type="secondary" style={{ fontSize: "14px", marginBottom: "16px" }}>
    {moment(date).format("MMMM Do YYYY, h:mm a")}
  </Text>
);

const DeleteCommentButton = ({ onDelete, id, sessionIndex }) => (
  <Button
    onClick={() => onDelete(id, sessionIndex)}
  >
    <DeleteIcon color="secondary" />
  </Button>
);

const SaveCommentButton = ({ onSave, id }) => (
  <Button onClick={() => onSave(id)}>
    <SaveIcon color="primary" />
  </Button>
);
const EditCommentButton = ({ onEdit, isEditing }) =>
  !isEditing && (
    <Button onClick={onEdit}>
      <EditIcon color="primary" />
    </Button>
  );

const CloseButton = ({ onClose, isEditing }) =>
  isEditing && (
    <Button onClick={onClose}>
      <CloseIcon color="secondary" />
    </Button>
  );

const CommentListPanel = ({
  sortedComments,
  onDeleteComment,
  onEditComment,
  sessionIndex,
}) => {
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [editedAuthor, setEditedAuthor] = useState("");
  const [editedDate, setEditedDate] = useState("");
  const [editedVisibilidad, setEditedVisibilidad] = useState("");
  const { user } = useSelector(state => state.auth);

  const handleEdit = (comment) => {
    setEditingCommentId(comment.id);
    setEditedText(comment.text);
    setEditedAuthor(comment.author);
    setEditedDate(comment.date);
    setEditedVisibilidad(comment.visibilidadFormador);
  };

  const handleSave = () => {
    onEditComment({ id: editingCommentId, text: editedText, author: editedAuthor, date: editedDate, visibilidadFormador: editedVisibilidad });
    setEditingCommentId(null);
  };

  const handleCancel = () => {
    setEditingCommentId(null);
  };
  if (sortedComments.length === 0) {
    return (
      <div>
        <Text type="secondary">No hay comentarios</Text>
      </div>
    );
  }
  return (
    <div style={{ zIndex: "1000" }}>
      {sortedComments.map((comment) => (
        <Card
          key={comment.id}
          style={{
            marginBottom: "24px",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            backgroundColor: "#fff",
          }}
          bodyStyle={{ padding: "16px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <AuthorText author={comment.user && comment.user.author && comment.user.author.email} visibilidadFormador={comment.visibilidadFormador} />
            <DateText date={comment.date} />

            <div>
              {comment.user.author.id === user.id && (
                <>
                  <EditCommentButton
                    onEdit={() => handleEdit(comment)}
                    isEditing={editingCommentId === comment.id}
                  />
                  {editingCommentId === comment.id && (
                    <SaveCommentButton onSave={handleSave} id={comment.id} />
                  )}
                  {editingCommentId === comment.id && (
                    <CloseButton
                      onClose={() => handleCancel()}
                      isEditing={editingCommentId === comment.id}
                    />
                  )}
                  {editingCommentId !== comment.id && (
                    <DeleteCommentButton
                      onDelete={onDeleteComment}
                      id={comment.id}
                      sessionIndex={sessionIndex}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {editingCommentId === comment.id ? (
            <ReactQuill
              value={editedText}
              theme={"snow"}
              onChange={(content, delta, source, editor) => {
                setEditedText(editor.getHTML());
              }}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["blockquote", "code-block"],
                  [{ header: 1 }, { header: 2 }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ direction: "rtl" }],
                  [{ size: ["small", false, "large", "huge"] }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                ],
              }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: comment.text }} />
          )}
        </Card>
      ))}
    </div>
  );
};

export default CommentListPanel;
