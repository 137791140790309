import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

import './Login.css';

import { startLogin } from '../../actions/auth';

import { showError } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    icon,
    id,
    key
}) => (

    <div className="row margin">
        <div className="input-field col s12">
            <i className="material-icons prefix pt-2">{ icon }</i>
            <input { ...input } key={key} id={ id } type={ type } />
            <label htmlFor={input.name} className="center-align">
                { label }
            </label>
            <small className="errorTxt6">
                <div id="crole-error" className="error">
                    {touched && error}
                </div>
            </small>
        </div>
    </div>
    

);

const validate = (values) => {
    const errors = {};    

    if (!values.username) {
        errors.username = i18n.t('validator-error-username');
    }

    if (!values.password) {
        errors.password = i18n.t('validator-error-password');
    }

    return errors;
}

const ScreensUserLogin = ({ history, submitting, handleSubmit }) => {
    
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleLogin = (values) => {

        const { username, password } = values;

        const lastPath = localStorage.getItem('lastPath') || '/';

        dispatch(
            startLogin(
                stringTrim(username), 
                stringTrim(password)
            )
        ).then((res) => {
            //console.log(res);
            if (res.ok) {
                history.replace(lastPath);
            } else {
                showError(
                    t('message-title-error'),
                    t(res.message)
                );
            }
        });

    };

    const renderForm = () => {

        return (
            <>
                <form className="login-form" onSubmit={ handleSubmit(handleLogin) }>
                    <div className="row">
                        <div className="input-field col s12" style={{textAlign: 'center'}}>
                            <img src="/assets/images/logo.png" alt="ERP - Sistema de Gestión" className="responsive-img" style={{maxWidth: "35%", "marginRight": "15px"}} />                                
                            <h5 style={{fontSize: '20px'}}>
                                { t('login_title') }
                            </h5>
                        </div>
                    </div>

                    <Field type="text" name="username" id="username" 
                        label={ t('input_username') } 
                        component={ renderField } 
                        icon="person_outline" 
                        key="username"
                    />

                    <Field type="password" name="password" id="password" 
                        label={ t('input_password') } 
                        component={ renderField } 
                        icon="lock_outline" 
                        key="password" 
                    />

                    {/* {<div className="row">
                        <div className="col s12 m12 l12 ml-2 mt-1">
                            <p>
                                <label>
                                    <input type="checkbox" />
                                    <span>
                                        { t('input_rememberme') }
                                    </span>
                                </label>
                            </p>
                        </div>
                    </div>} */}

                    <div className="row">
                        <div className="input-field col s12">
                            <button type="submit" className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12" disabled={ submitting }>
                                { t('login_button_title') }
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s12">
                            <p className="margin medium-small">
                                <Link to={ '/olvide-password' }
                                    title={ t('link_forget_password') }
                                >
                                    { t('link_forget_password') }
                                </Link>
                            </p>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="input-field col s12">
                            <p className="margin medium-small">
                                <Link to={ '/primer-acceso' }
                                    title={ t('link_first_access') }
                                >
                                    { t('link_first_access') }
                                </Link>
                            </p>
                        </div>
                    </div> */}
                </form>
            </>
        );

    };

    return (
        <div className="row">
            <div className="col s12">
                <div className="container">
                    <div id="login-page" className="row">
                        <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                
                            { renderForm() }
                
                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'loginForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserLogin); 