import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, DateObject } from "react-multi-date-picker";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Card, CardContent, Typography } from '@material-ui/core';
import {
  fetchEvents,
  fetchTrainerAvailabilityDays,
  editTrainerAvailabilityDays,
  deleteTrainerAvailabilityDays
} from "../../actions/planner";
import "./DisponibilidadTrainerForm.css";//
import { set } from "lodash";
import { da } from "date-fns/locale";

const spanishLocale = {
  name: "gregorian_es",
  months: [
    ["Enero", "Ene"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "May"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sep"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"]
  ],
  weekDays: [
    ["Sábado", "Sáb"],
    ["Domingo", "Dom"],
    ["Lunes", "Lun"],
    ["Martes", "Mar"],
    ["Miércoles", "Mié"],
    ["Jueves", "Jue"],
    ["Viernes", "Vie"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"]
  ]
};



const useDateManagement = (initialDates = []) => {
  const [dates, setAvailableDates] = useState(initialDates);
  const [isProcessing, setIsProcessing] = useState(false);
  const [feedback, setFeedback] = useState('');
  const dispatch = useDispatch();


  const handleAvailabilityUpdate = async (datesToUpdate, isAdding) => {
    try {
      setIsProcessing(true);
      const formattedDates = datesToUpdate.map(date =>
        typeof date === 'number'
          ? new DateObject(date).format('YYYY-MM-DD')
          : date.format('YYYY-MM-DD')
      );

      const result = await dispatch(
        isAdding
          ? editTrainerAvailabilityDays(formattedDates)
          : deleteTrainerAvailabilityDays(formattedDates)
      );

      if (result.ok) {
        setFeedback('Cambios guardados');
      } else {
        setFeedback('Error al guardar los cambios');
      }
    } catch (error) {
      console.error('Error updating availability:', error);
      setFeedback('Error al procesar la solicitud');
    } finally {
      setIsProcessing(false);
      setTimeout(() => setFeedback(''), 2000);
    }
  };

  const getDatesToProcess = (selectedDate, mode, currentDates) => {
    const datesToProcess = new Set();
    alert('SelectedDate: ' + selectedDate.toString());
    if (mode === 'single') {
      datesToProcess.add(selectedDate.unix * 1000);
    }

    return Array.from(datesToProcess);
  };

  const handleDateChange = useCallback(async (newDates, mode, currentDates) => {
    if (!Array.isArray(newDates)) newDates = [newDates];
    if (newDates.length === 0) return;

    const selectedDate = new DateObject();

    const currentDateSet = new Set(currentDates.map(d => d.unix * 1000));
    const datesToProcess = getDatesToProcess(selectedDate, mode, currentDates);

    // Determinar si estamos agregando o eliminando fechas
    const isAdding = !currentDateSet.has(selectedDate.unix * 1000);

    await handleAvailabilityUpdate(datesToProcess, isAdding);

    // Actualizar estado local
    const newDateSet = new Set(currentDates.map(d => d.unix * 1000));
    datesToProcess.forEach(timestamp => {
      if (isAdding) {
        newDateSet.add(timestamp);
      } else {
        newDateSet.delete(timestamp);
      }
    });

    setAvailableDates(
      Array.from(newDateSet).map(timestamp => new DateObject(timestamp))
    );
  }, []);

  return {
    dates,
    setDates: setAvailableDates,
    isProcessing,
    feedback,
    handleDateChange
  };
};

const ModeButton = React.memo(({ active, onClick, children }) => (
  <button
    className={`mode-btn ${active ? 'active' : ''}`}
    onClick={onClick}
    aria-pressed={active}
  >
    {children}
  </button>
));


function DisponibilidadTrainerForm() {
  const dispatch = useDispatch();
  const { availabilityDays, eventsList } = useSelector((state) => state.planner);
  const [isLoading, setIsLoading] = useState(true);
  const [selectionMode, setSelectionMode] = useState('single');
  const [error, setError] = useState(null);
  const { eventFilter } = useSelector((state) => state.planner);
  const { availabilityDaysFilter } = useSelector((state) => state.planner);
  const { selected } = useSelector(state => state.trainer);
  const { user } = useSelector(state => state.auth);

  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(availabilityDays);
  }, [availabilityDays]);

  const formattedDates = useMemo(() => {
    const dateStrings = availabilityDays || [];
    return dateStrings.map(dateStr => new DateObject(dateStr));
  }, [availabilityDays]);

  const {
    dates: availableDates,
    setDates: setAvailableDates,
    isProcessing,
    feedback,
    handleDateChange
  } = useDateManagement(formattedDates);


  // Mover todos los hooks antes del condicional
  const availableDatesMap = useMemo(() => {
    const map = new Map();
    availableDates?.forEach(date => {
      if (date?.unix) {
        map.set(date.unix * 1000, true);
      }
    });
    return map;
  }, [availableDates]);



  const isDateAssigned = useMemo(() => {
    const assignedDatesMap = new Map();
    eventsList?.forEach(event => {
      const formattedDate = new DateObject(event.f_inicio).format('YYYY-MM-DD');
      assignedDatesMap.set(formattedDate, true);
    });
    return formattedDate => assignedDatesMap.has(formattedDate);
  }, [eventsList]);


  const onDateChange = useCallback(async (newDates) => {
    if (isProcessing) return;

    const processDates = async (dates, action) => {
      //console.log('Procesando fechas:', dates.toString());
      //console.log('Action:', action);

      //Validar que dates sea de tipo objeto Date sino convertirlo     

      const formattedDates = dates.map(date =>
        typeof date === 'string'
          ? new DateObject(date).format('YYYY-MM-DD')
          : date.format('YYYY-MM-DD')
      );

      const trainer_id = selected ? selected.id : user.trainers[0].id

      try {
        const result = await dispatch(action(formattedDates, trainer_id));
        if (result.ok) {
          console.log('Cambios guardados');
          // setFeedback('Cambios guardados');
        } else {
          console.log('Error al guardar los cambios');
          // setFeedback('Error al guardar los cambios');
        }
      } catch (error) {
        console.error('Error updating availability:', error);
        // setFeedback('Error al procesar la solicitud');
      } finally {
        setValues(newDates);
        setAvailableDates(newDates);
        // setTimeout(() => setFeedback(''), 2000); 
      }
    };

    let newDatesFormated = newDates.map(date => date.format('YYYY-MM-DD'));
    /* console.log('Valores actuales:', values.toString()); 
    console.log('Nuevas fechas:', newDates.toString()); */

    if (newDates.length < values.length) {
      // Se ha eliminado un elemento            
      await processDates(newDates, deleteTrainerAvailabilityDays);

    } else if (newDates.length > values.length) {
      // Se ha agregado un elemento
      const addedElements = newDatesFormated.filter(value => !values.includes(value));      
      await processDates(addedElements, editTrainerAvailabilityDays);
    }

  }, [isProcessing, values, dispatch]);


  // Función para deshabilitar el clic
  function disableClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  const mapDays = useCallback(({ date }) => {   
    const dateTimestamp = date.unix * 1000;
    const formattedDate = date.format('YYYY-MM-DD');
    const props = {
      role: 'button',
      'aria-label': date.format('DD/MM/YYYY'),
    };

    if (isDateAssigned(formattedDate)) {
      props.className = "blue";
      props['aria-label'] += ' - Fecha asignada';
      // Asignar el manejador de evento para deshabilitar el clic
      props.onClick = disableClick;
    } else if (availableDatesMap.has(dateTimestamp)) {
      props.className = "green";
      props['aria-label'] += ' - Fecha disponible';
    } else {
      props.className = "red";
      props['aria-label'] += ' - Fecha no disponible';
    }

    return props;
  }, [isDateAssigned, availableDatesMap]);

  useEffect(() => {
    if (formattedDates.length > 0) {
      setAvailableDates(formattedDates);
    }
  }, [formattedDates, setAvailableDates]);

  useEffect(() => {
    const loadData = async () => {
      try {
        await dispatch(fetchEvents(eventFilter));
        await dispatch(fetchTrainerAvailabilityDays(availabilityDaysFilter));
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
    //alert('ID: ' + availabilityDaysFilter.trainer_id);
    //Set values es el que actualiza los verdes.
    setValues(availabilityDays);
  }, [dispatch]);


  if (isLoading || !availabilityDays || !eventsList) {
    return <div className="loading" role="alert" aria-busy="true">Cargando datos...</div>;
  }
  const TrainerCard = () => {
    return (
      <h2 className="calendar-title" style={{ border: '1px solid #ccc', padding: '10px', color: 'blue' }}>
        - {selected ? selected.label_mail : user.trainers[0].label} -
      </h2>
    );
  };

  return (
    <ErrorBoundary>
      <div className="calendar-container" role="main">
        <h5 className="calendar-title"> Calendario de Disponibilidad</h5>
        <br />
        <TrainerCard />
        <ul className="calendar-instructions">
          <li>
            <span style={{ color: '#2ecc71', marginRight: '5px', fontSize: '2em' }}>●</span>
            Las fechas resaltadas en verde indican que estás disponible.
          </li>

          <li>
            <span style={{ color: 'rgb(74, 144, 226)', marginRight: '5px', fontSize: '2em' }}>●</span>
            Las fechas resaltadas en azul indican que tienes eventos programados.
          </li>
        </ul>

        {feedback && (
          <div className="feedback-message" role="status" aria-live="polite">
            {feedback}
          </div>
        )}

        <div className={`calendar-wrapper ${isProcessing ? 'processing' : ''}`}>
          <Calendar
            value={availabilityDays}
            onChange={onDateChange}
            mapDays={mapDays}
            locale={spanishLocale}
            fullYear
            multiple
            disabled={isProcessing}
            className="custom-calendar"
            monthYearSeparator=" "
            showOtherDays={false}
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "800px",
              margin: "0 auto"
            }}
            aria-label="Availability calendar"
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

const MemoizedDisponibilidadTrainerForm = React.memo(DisponibilidadTrainerForm);
export default MemoizedDisponibilidadTrainerForm;

