//ToDo
// 1. Refactor the code below to use styled-components
// 2. Use the styled-components in the Modal component
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Modal from "react-modal";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { fetchEvents, selectEvent } from "../../actions/planner";
import { startLoading, endLoading } from "../../actions/ui";
import { showError } from "../../helpers/messages";
import { useTranslation } from "react-i18next";
import CustomToolbar from "./components/CustomToolbar";
import DisponibilidadTrainerForm from "./DisponibilidadTrainerForm";
import PageTitle from "../../components/UI/PageTitle";
import PageContainer from "../../components/UI/PageContainer";
import { darken } from "polished";
import { set } from "lodash";
import LoadingScreen from "../../hooks/common/loadingScreen";

const localizer = momentLocalizer(moment);

const DisponibilidadTrainerScreen = () => {
  
  const { t } = useTranslation();   

  return (
 
      <div>        
        <DisponibilidadTrainerForm />
      </div>

  );
};

export default DisponibilidadTrainerScreen;