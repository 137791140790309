import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../helpers/messages';

import TrainerSearchForm from './SearchForm';
import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';
import FloatingButtonAction from '../../components/UI/FloatingButtonAction';

import {
    fetchTrainers,
    downloadExcel,
    selectTrainer,
    deleteTrainer
} from '../../actions/trainer';
import {
    startLoading,
    endLoading
} from '../../actions/ui';
import { setEventFilter, setTrainerAvailabilityDaysFilter } from '../../actions/planner';

const ScreensTrainerList = ({ nombre, apellidos, demarcacion_id }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.trainer);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchTrainers(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    useEffect(() => {
        dispatch(selectTrainer(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleEdit = (item) => {
        dispatch(selectTrainer(item));
        history.push('/formadores-homologados/formulario');
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-trainer-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteTrainer(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                nombre: nombre,
                apellidos: apellidos,
                demarcacion_id: demarcacion_id
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleAdd = () => {
        dispatch(selectTrainer(null));
        history.push('/formadores-homologados/formulario');
    };

    const handleAvailability = (item) => {

        // Set filter for events by trainer ID
        dispatch(setEventFilter({
            trainer_id: [item.id],
            estados: ['1'], // Active events only
            action: 'disponibilidad'
        }));
        dispatch(setTrainerAvailabilityDaysFilter({ trainer_id: item.id }));
        dispatch(selectTrainer(item));
        history.push('/planner/disponibilidadFormador');
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={t('screens-trainer-list-apellidos') + ' - ' + t('screens-trainer-list-nombre')}
                            direction={(sort === 'apellidos') ? direction : ''}
                            onClick={() => handleSort('apellidos')}
                        />
                        <SortingHeader title={t('screens-trainer-list-dni')}
                            direction={(sort === 'dni') ? direction : ''}
                            onClick={() => handleSort('dni')}
                        />
                        <th>{t('screens-trainer-list-especialidad')}</th>
                        <th>{t('screens-trainer-list-titulacion')}</th>
                        <th>{t('screens-user-selector-active')}</th>
                        <th>{t('screens-list-acciones')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={item.id}>
                                <td>{item.label}</td>
                                <td>{item.dni}</td>
                                <td>{item.especialidad}</td>
                                <td>{item.titulaciones}</td>
                                <td>
                                    {item.activo ? (
                                        <span className="chip green lighten-5">
                                            <span className="green-text">{t("Activo")}</span>
                                        </span>
                                    ) : (
                                        <span className="chip red lighten-5">
                                            <span className="red-text">{t("Desactivado")}</span>
                                        </span>
                                    )}
                                </td>
                                <td>
                                    <Can perform="FormadoresHomologados:edit" yes={() => (
                                        <ActionButton icon="edit"
                                            tooltip={t('screens-list-acciones-edit')}
                                            extraClass="btn-floating btn-small waves-effect waves-light green darken-3" 
                                            onClick={() => handleEdit(item)} />
                                    )} />
                                    <Can perform="FormadoresHomologados:edit" yes={() => (
                                        <ActionButton icon="event"
                                            tooltip={t('screens-list-acciones-availability')}
                                            extraClass="btn-floating btn-small waves-effect waves-light blue darken-3 ml-3"
                                            onClick={() => handleAvailability(item)} />
                                    )} />
                                    <Can perform="FormadoresHomologados:delete" yes={() => (
                                        <ActionButton icon="delete_forever"
                                            tooltip={t('screens-list-acciones-delete')}
                                            extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3"
                                            onClick={() => handleDelete(item.id, `${item.label}`)} />
                                    )} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={t('screens-trainer-list-title')}>
                <Can perform="FormadoresHomologados:excel" yes={() => (
                    <ExcelButton onPressButton={handleExcel} />
                )} />
            </PageTitle>

            <TrainerSearchForm />

            <ContentContainer>
                {renderTable()}
            </ContentContainer>

            <Pagination paging={paging} onPaginate={handlePaginate} />

            <Spacer height="5" />

            <Can perform="FormadoresHomologados:add" yes={() => (
                <FloatingButtonAction icon="add"
                    title={t('screens-list-acciones-add')} action={handleAdd}
                />
            )} />

        </PageContainer>
    );

};

const formSelector = formValueSelector('trainerSearchForm');

export default connect(state => ({
    nombre: formSelector(state, 'nombre'),
    apellidos: formSelector(state, 'apellidos'),
    demarcacion_id: formSelector(state, 'demarcacion_id')
}))(ScreensTrainerList);